.navbar-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  z-index: 1000;
  -webkit-tap-highlight-color: transparent;
}

.navbar {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 15px 30px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.navbar:hover {
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.3);
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 30px;
}

.mobile-menu-icon {
  display: none;
  font-size: 28px;
  background: none;
  border: none;
  color: #ececee;
  cursor: pointer;
}

@media (max-width: 768px) {
.navbar{
    width: 80%;
    -webkit-tap-highlight-color: transparent;
}
  .navbar-menu {
    position: absolute;
    top: 80px;
    right: 20px;
    background: rgba(28, 28, 28, 0.95); /* Dark fallback for iOS */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    width: 200px;
    -webkit-transform: translateX(120%);
    transform: translateX(120%);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    -webkit-tap-highlight-color: transparent;
  }

  .navbar-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .mobile-menu-icon {
    display: block;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
  }
}

.navbar-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 18px;
  transition: all 0.3s ease;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(45deg, #a779ff, #7038ff);
  transition: width 0.3s ease;
}

.navbar-link:hover::after,
.navbar-link.active::after {
  width: 100%;
}

.navbar-link:hover,
.navbar-link.active {
  color: #fff;
  text-shadow: 0 0 20px rgba(167, 121, 255, 0.5);
}

.navbar-link:active,
.navbar-link:focus {
  color: rgba(255, 255, 255, 0.8);
  outline: none;
}

.navbar-logo img {
  width: 50px;
  height: 50px;
  border-radius: 10%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.navbar-logo img:hover {
  transform: scale(1.1);
}
