.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-content {
  padding: 20px;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: none;  /* Remove background from here */
}

/* iOS-specific fix */
@supports (-webkit-touch-callout: none) {
  .app-container {
    background-attachment: scroll;
  }
}

@supports not (-webkit-touch-callout: none) {
  .app-container {
    background-attachment: fixed;
  }
}

.background-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.app-container::before {
  position: fixed; /* Change to fixed */
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust opacity as needed */
  z-index: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.main-content {
  position: relative;
  z-index: 2;
  padding: 20px;
  min-height: calc(100vh - 60px); /* Adjust based on your navbar height */
  flex: 1;
}

.page-title {
  color: white;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 10rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media (max-width: 480px) {
  .page-content {
    padding: 15px;
  }

  .main-content {
    padding: 15px;
  }

  .page-title {
    font-size: 2rem;
    margin-top: 6rem;
    margin-bottom: 1.5rem;
  }
}