.footer {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 40px 20px 20px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: auto;
  z-index: 1000;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 40px;
}

.footer-section h4 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-brand h3 {
  font-size: 2rem;
  margin: 0;
  background: linear-gradient(25deg, #ffffff, #55006f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  line-height: 1;
  text-shadow: 0 4px 12px rgba(231, 222, 248, 0.15);
}

.footer-brand p {
  margin-top: 10px;
  font-size: 0.9rem;
  opacity: 0.8;
}

.quick-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.quick-links a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s ease;
}

.quick-links a:hover {
  color: #a779ff;
  transform: translateX(5px);
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  color: #a779ff;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 30px;
  }

  .footer-brand h3,
  .footer-brand p {
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }

  .quick-links a:hover {
    transform: none;
  }
}