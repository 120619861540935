.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  margin: 20px auto;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1200px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s forwards;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .card {
    width: 95%;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }
}

/* Specific styles for cards in support page */
.support .card {
  background: rgba(0, 0, 0, 0.1);  /* Darker tint */
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Specific styles for cards in policy pages */
.policy-section.card {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.policy-section.card:hover {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(219, 121, 255, 0.2);
  box-shadow: 0 8px 32px rgba(219, 121, 255, 0.1);
}


@media (max-width: 480px) {
  .policy-section.card {
    background: rgba(0, 0, 0, 0.2);
    border-color: rgba(219, 121, 255, 0.2);
    box-shadow: 0 8px 32px rgba(219, 121, 255, 0.1);
  }
}