body {
  margin: 0;
  padding: 0;
  color: #ececee;
  font-family: 'Poppins', sans-serif;
}

.home {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.home .download {
  padding: 50px 20px;
  text-align: center;
}

.home .download h2 {
  font-size: 36px;
  color: #ffffff;
}

.download-buttons {
  margin-top: 20px;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;
}

.download-buttons a {
  transition: background 0.3s ease, transform 0.3s ease;
}

.btn-download {
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Add this */
  gap: 0.8rem;
  padding: 1rem 2.5rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2rem;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.store-icon {
  font-size: 1.4rem;
  margin-top: -2px; /* Slight adjustment for visual alignment */
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.btn-download::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #db79ff, #7038ff);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.btn-download:hover::before {
  opacity: 1;
}

.btn-download:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.responsive-image:hover {
  transform: scale(1.02);
}

.intro,
.feature,
.download {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}

.intro h2,
.feature h2,
.download h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.intro .responsive-image,
.feature .responsive-image {
  width: 45%;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Remove the following sections as they're no longer needed */
/* .cards-container, .card, .floating-section, .full-width-section */

.hero-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: sticky;
  top: 0;
  overflow: hidden;
  margin-bottom: 4rem;
}

/* Remove or comment out this duplicate section */
/*.hero-section h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #fff, #db79ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}*/

.hero-section p {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.feature-card {
  margin: 4rem auto;
  overflow: hidden;
}

.feature-card.dark {
  background: rgba(0, 0, 0, 0.7);
}

.feature-card.gradient {
  background: linear-gradient(135deg, rgba(167, 121, 255, 0.1), rgba(0, 0, 0, 0.8));
}

.feature-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  margin: 2rem 0;
}

.cta-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  position: relative;
}

.cta-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.cta-left {
  flex: 1;
  text-align: center;  /* Change from left to center */
  padding-left: 0;     /* Remove left padding */
  display: flex;
  flex-direction: column;
  align-items: center;  /* Change from flex-start to center */
  max-width: 600px;  /* Match title max-width */
}

.cta-right {
  flex: 1;
  display: flex;
  justify-content: center;
}

.gradient-text.secondary {
  text-align: center;  /* Change from left to center */
  margin-bottom: 3rem;
  font-size: 5rem;
  line-height: 1;
  text-shadow: 0 4px 12px rgba(255, 255, 255, 0.15);  /* Change shadow color to white */
  max-width: 600px;  /* Set max-width for title */
}

.download-buttons {
  display: flex;
  flex-direction: row;  /* Change to row */
  justify-content: center;  /* Change from space-between to center */
  width: 100%;  /* Take full width of container */
  gap: 2rem;
  margin-top: 2rem;
}

.download-buttons a {
  transition: background 0.3s ease, transform 0.3s ease;
}

.btn-download {
  flex: 1;  /* Make buttons equal width */
  min-width: 0;  /* Reset min-width */
  padding: 1.2rem 2rem;
  text-align: center;
}

.app-previews {
  position: relative;
  transform: perspective(1000px) rotateY(-5deg);
  transition: transform 0.6s ease;
}

.app-previews:hover {
  transform: perspective(1000px) rotateY(0deg);
}

.intro, .feature {
  padding: 2rem;
}

.intro h2, .feature h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #fff, #db79ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intro p, .feature p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .cta-content {
    gap: 2rem;
  }

  .gradient-text.secondary {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  /* .hero-section h1 {
    font-size: 2.5rem;
  }
   */
  .hero-section p {
    font-size: 1.2rem;
  }
  
  .intro h2, .feature h2 {
    font-size: 2rem;
  }
  /* Remove duplicate media query styles */
  /*.hero-section h1 {
    font-size: 2.5rem;
  }*/

  .cta-content {
    flex-direction: column;
    text-align: center;
    gap: 3rem;
  }

  .cta-left {
    padding-left: 0;
    text-align: center;
  }

  .download-buttons {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .btn-download {
    width: 100%;
  }

  .gradient-text.secondary {
    text-align: center;
    font-size: 3rem;
  }

  .gradient-text {
    font-size: 5rem;
  }
  
  .feature-panel {
    width: 90vw;
    height: 60vh;
  }
  
  .app-previews {
    flex-direction: column;
    align-items: center;
    gap: 4rem;
  }
  
  .preview-container {
    width: 180px;
    height: 360px;
  }

  .cta-left {
    padding: 0;
    width: 100%;
    max-width: 400px;  /* Limit width on mobile */
    margin: 0 auto;
  }

  .download-buttons {
    flex-direction: row;  /* Keep buttons in row */
    justify-content: center;
    gap: 1rem;
    width: auto;  /* Remove 100% width */
    margin: 2rem auto;
  }

  .btn-download {
    flex: 0 1 auto;  /* Remove flex grow */
    width: auto;  /* Remove fixed width */
    min-width: 140px;  /* Set minimum width */
    padding: 1rem 1.5rem;
  }

  .gradient-text.secondary {
    width: 100%;
    max-width: 400px;  /* Match parent width */
    margin: 0 auto 2rem;
  }

  .app-previews {
    flex-direction: column;
    align-items: center;
    gap: 5rem;  /* Increase gap between previews */
    padding: 3rem 1rem;  /* Adjust padding */
    margin: 1rem 0;  /* Reduce vertical margin */
  }

  .preview-container {
    margin: 2rem 0;  /* Add vertical margin to containers */
    height: 380px;  /* Slightly taller containers */
  }

  .preview-image {
    transform: translateZ(0);  /* Force GPU acceleration */
    backface-visibility: hidden;  /* Prevent glitches */
  }
}

.modern-home {
  min-height: 100vh;
  perspective: 1000px;
}

.gradient-text {
  font-size: 12rem;
  font-weight: 900;
  background: linear-gradient(45deg, #fff 30%, #db79ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  letter-spacing: -0.02em;
  line-height: 0.9;
  text-transform: uppercase;  /* Add this to ensure uppercase */
}

.gradient-text.secondary {
  font-size: 4rem;
  margin-bottom: 2rem;
  font-weight: 800;
  background: linear-gradient(45deg, #fff 20%, #db79ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  line-height: 1;
}

@media (max-width: 768px) {
  .gradient-text {
    font-size: 5rem;
  }
  
  .gradient-text.secondary {
    font-size: 3rem;
  }
  
  .feature-panel {
    width: 90vw;
    height: 60vh;
  }
  
  .app-previews {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .preview-container {
    width: 180px;
    height: 360px;
  }
}

.hero-subtitle {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 1rem;
}

.scroll-indicator {
  position: absolute;
  bottom: 2rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
}

.horizontal-scroll-section {
  height: 100vh;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scroll-content {
  display: inline-flex;
  padding: 2rem;
  gap: 2rem;
}

.feature-panel {
  width: 80vw;
  height: 70vh;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 2rem;
  padding: 2rem;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: perspective(1000px) rotateY(-5deg);
  transition: transform 0.6s ease;
}

.feature-panel:hover {
  transform: perspective(1000px) rotateY(0deg);
}

.feature-panel img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  object-fit: cover;
}

.gradient-circle {
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #db79ff, #7038ff);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.app-previews {
  display: flex;
  gap: 3rem;
  margin: 3rem 0;
  justify-content: center;
  padding: 2rem;
}

.app-previews img {
  width: 200px;
  height: auto;
  border-radius: 1.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.preview-container {
  position: relative;
  width: 200px;
  height: 400px;
  perspective: 1000px;
  cursor: pointer;
  will-change: transform;
  transform-style: preserve-3d;
}

.preview-image {
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  object-fit: cover;
  pointer-events: none;
}

@media (max-width: 768px) {
  .gradient-text {
    font-size: 5rem;
  }
  
  .feature-panel {
    width: 90vw;
    height: 60vh;
  }
  
  .app-previews {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .preview-container {
    width: 200px;
    height: 360px;
  }
}

@media (max-width: 460px) {
    .gradient-text {
      font-size: 4rem;
    }
}

/* Replace modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-sizing: border-box;
}

.modal-content {
  position: sticky;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2rem;
  padding: 3rem;
  margin-top: 90vh;
  text-align: center;
  max-width: 500px;
  width: 90%;
  color: white;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.modal-content p {
  font-size: 1.2rem;
  margin: 1rem 0;
  color: rgba(255, 255, 255, 0.8);
}

.modal-content .btn-download {
  margin-top: 2rem;
  width: auto;
  display: inline-flex;
  border: none;
  cursor: pointer;
  background: linear-gradient(45deg, #db79ff, #7038ff);

}

@media (max-width: 768px) {
  .modal-content {
    padding: 2rem;
    width: 85%;
    position: sticky;
    margin-top: 0vh;
  }
  
  .modal-content h3.gradient-text {
    font-size: 2rem !important;
  }
  
  .modal-content p {
    font-size: 1rem;
  }
}