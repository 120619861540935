
.responsive-image.animated {
  opacity: 0;
  transform: scale(0.95);
  animation: popIn 0.5s forwards;
}

@keyframes popIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}