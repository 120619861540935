.policy-page {
  padding: 120px 20px 40px;
  max-width: 800px;
  margin: 0 auto;
}

.policy-header {
  text-align: center;
  margin-bottom: 40px;
}

.policy-header h1 {
  font-size: 3rem;
  background: linear-gradient(to right, #fff, #db79ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.policy-header p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.policy-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.policy-section {
  padding: 30px;
  transition: transform 0.3s ease;
}

.policy-section:hover {
  transform: translateY(-5px);
}

.policy-section h2 {
  color: linear-gradient(to right, #fff, #db79ff);
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.policy-section p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.policy-content-text {
  line-height: 1.6;
  white-space: pre-line;
}

.policy-content-text b {
  color: #db79ff;
}


