.support {
  min-height: 100vh;
  padding: 120px 20px 60px;
  margin-top: 7rem;
  border-radius: 2%;
  position: relative;
  color: #ececee;
  font-family: 'Poppins', sans-serif;
  /* background: linear-gradient(135deg, #1f1f1f, #2c2c2c); */
}

.support-header {
  text-align: center;
  margin-top: -3rem;
  margin-bottom: 60px;
}

.support-header h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #fff 30%, #db79ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.support-header p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

.support-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Change to equal columns */
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;  /* Add this to ensure full width */
}

.contact-card, .info-card {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.contact-form {
  padding: 2rem;
  max-width: none;  /* Remove max-width constraint */
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.contact-form h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, #fff, #db79ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-group {
  margin-bottom: 1.5rem;
  max-width: 100%;  /* Ensure form group doesn't exceed container */
}

.form-group input,
.form-group select,
.form-group textarea {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;  /* Ensure inputs don't exceed container */
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(219, 121, 255, 0.2);
  border-radius: 12px;
  color: #fff;  /* Change color to white */
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.form-group select {
  box-sizing: border-box;  /* Add this to fix width calculation */
  width: 100%;
  padding: 1rem 1.5rem;
  /* background: rgba(0, 0, 0, 0.2); */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 1.5rem;
  padding-right: 3.5rem;
  cursor: pointer;
  margin: 0;  /* Add this to remove any default margins */
  min-width: 100%;  /* Add this to ensure minimum width */
}

.form-group select:focus {
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23db79ff'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
}

.form-group select option {
  background-color: #1a1a1a;
  color: white;
  padding: 1rem;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);  /* Add this for placeholder text */
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  background: rgba(219, 121, 255, 0.05);
  border-color: #db79ff;
  outline: none;
  box-shadow: 0 0 20px rgba(219, 121, 255, 0.2);
}

.contact-form button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(45deg, rgba(219, 121, 255, 0.9), rgba(112, 56, 255, 0.9));
  border: none;
  border-radius: 12px;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(219, 121, 255, 0.3);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.contact-form button:hover {
  background: linear-gradient(45deg, rgba(219, 121, 255, 1), rgba(112, 56, 255, 1));
  box-shadow: 0 4px 20px rgba(219, 121, 255, 0.4);
}

.contact-form button:disabled {
  background: rgba(219, 121, 255, 0.3);
  cursor: not-allowed;
}

.company-info {
  padding: 2rem;
  text-align: center;
  margin: 40px 0;
  border-top: 1px solid #444;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 2rem 0;
}

.info-item {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.info-item svg {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #db79ff;
}

.socials {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.socials a {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
}

.socials a:hover {
  color: #db79ff;
}

.support-content {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 968px) {
  .support-grid {
    grid-template-columns: 1fr;
    width: 100%;  /* Changed from 95% to 100% */
    max-width: 600px;  /* Add max-width for better control */
    margin: 0 auto;
    padding: 0;  /* Add consistent padding */
  }
  
  .support-header h1 {
    font-size: 2.5rem;
  }

  .contact-card, .info-card {
    width: 100%;  /* Make cards take full width of their container */
    margin: 0 auto;  /* Center the cards */
  }
}

@media (max-width: 768px) {
  .support {
    padding: 40px 1rem;  /* Changed from 0px to 1rem */
  }
  
  .contact-form {
    max-width: 100%;
    margin: 0 auto;  /* Changed from 0 10px to 0 auto */
    padding: 2rem 1rem;  /* Adjusted padding */
  }
}

/* Toast Customization */
.Toastify__toast {
  background: rgba(28, 28, 28, 0.95) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(219, 121, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  font-family: 'Poppins', sans-serif;
  color: #fff;
  border-radius: 8px;
  padding: 16px;
  font-family: inherit;
}

.Toastify__toast--success {
  border-left: 4px solid #db79ff;
}

.Toastify__toast--error {
  border-left: 4px solid #ff4466;
}

.Toastify__toast--loading {
  border-left: 4px solid #7038ff;
}

.Toastify__close-button {
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
}

.Toastify__close-button:hover {
  color: #db79ff;
}

.Toastify__progress-bar {
  background: linear-gradient(45deg, #db79ff, #7038ff);
}